import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { XCircle } from "react-feather"
import styled, { css } from "styled-components"
import logoSvg from "../../images/logo.svg"
import hotDog from "../../images/hot-dog.svg"
import HideReveal from "../../components/Typography/HideReveal"
import { withProp } from "@wigxel/react-components/lib/utils"

const StyledHeader = styled.header`
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.9);
`

const StyledMenu = styled.div`
  background-color: rgba(250, 250, 250, 0.9);
  backdrop-filter: blur(10px);
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
  padding: 2rem;
  position: fixed;
  text-align: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  transform: scale(1.5);
  transform-origin: center center;
  transition: all 0.2s ease-out;

  ${withProp(
    "show",
    css`
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
    `
  )}

  nav {
    width: 100%;
    flex: 1;
    padding-top: 30vh;
  }

  nav li {
    width: 100%;
    display: inline-block;
    padding: 1rem 0;
  }

  button {
    position: absolute;
    bottom: 2rem;
  }
`

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = React.useState(false)

  return (
    <React.Fragment>
      <div className="bg-yellow-200 flex items-center justify-center py-2">
        <div className="hover:underline text-sm md:px-4 w-full text-center">
          <Link to="/black-chef-africa-foundation">
            <span className="hidden md:flex w-full justify-center">
              <b>Upcoming Event</b>: Black Chef Africa Scholarship
            </span>
            <marquee className="flex md:hidden">
              <b>Upcoming Event</b>: Black Chef Africa Scholarship
            </marquee>
          </Link>
        </div>
      </div>
      <StyledHeader className="flex py-8 sticky top-0 z-50">
        <div className="container flex justify-between items-center mx-auto px-4 md:px-0">
          <Link to="/" activeClassName="text-primary">
            <img
              src={logoSvg}
              alt={siteTitle}
              className="m-0 h-10 md:h-12 -my-6 object-cover"
            />
          </Link>
          <button
            className="appearance-none md:hidden"
            onClick={() => setShowMenu(true)}
          >
            <img src={hotDog} alt="hamburger" className="h-10 w-10" />
          </button>
          <nav className="hidden md:inline-flex">
            <li className="px-4 block text-opacity-100">
              <Link to="/" activeClassName="text-primary">
                <HideReveal text="HOME" />
              </Link>
            </li>
            <li className="px-4 block text-_1 text-opacity-50">
              <Link to="/about" activeClassName="text-primary">
                <HideReveal text="ABOUT" />
              </Link>
            </li>
            <li className="px-4 block text-_1 text-opacity-50">
              <Link to="/#courses" activeClassName="text-primary">
                <HideReveal text="COURSES" />
              </Link>
            </li>
          </nav>
        </div>
      </StyledHeader>
      <StyledMenu show={showMenu}>
        <nav className="flex flex-col items-center font-display tracking-widest text-xl md:text-2xl">
          <li className="px-4">
            <Link to="/" activeClassName="text-primary">
              <HideReveal text="HOME" />
            </Link>
          </li>
          <li className="px-4 block text-_1">
            <Link to="/about" activeClassName="text-primary">
              <HideReveal text="ABOUT" />
            </Link>
          </li>
          <li className="px-4 text-_1">
            <Link to="/#courses" activeClassName="text-primary">
              <HideReveal text="COURSES" />
            </Link>
          </li>
        </nav>
        <button onClick={() => setShowMenu(false)}>
          <XCircle strokeWidth={1} size={"4rem"} />
        </button>
      </StyledMenu>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
